@layer components {

    /* Toggle */
    .faq-item .faq-answer {
        @apply invisible h-0 opacity-0;
    }

    .faq-item.open .faq-answer {
        @apply visible h-full opacity-100;
    }

    /* Icon (closed) */
    .faq-item .faq-icon {
        @apply bg-white border border-global;
    }

    .faq-item .faq-icon svg {
        stroke: #386DEF;
    }

    /* Icon (open) */
    .faq-item.open .faq-icon {
        @apply bg-global rotate-45;
    }

    .faq-item.open .faq-icon svg {
        stroke: #fff;
    }
}