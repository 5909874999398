@layer components {
    .navbar-locales {
        @apply gap-3;
    }

    .navbar-locales a {
        @apply text-gray-500 hover:text-zinc-900;
    }

    .navbar-locales a:first-child {
        @apply pr-3 border-r border-gray-500;
    }

    .navbar-locales .active {
        @apply text-global underline hover:no-underline;
    }
}