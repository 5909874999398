@layer components {
    /* The switch - the box around the slider */
    .form-switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 22px;
    }

    /* Hide default HTML checkbox */
    .form-switch .form-check-input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .form-check-label {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 24px;
    }

    .form-check-label::before {
        position: absolute;
        content: "";
        height: 75%;
        left: 10%;
        bottom: 12.5%;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
        aspect-ratio: 1;
    }

    .form-check-input:checked + .form-check-label {
        @apply bg-global;
    }

    .form-check-input:focus + .form-check-label {
        box-shadow: 0 0 1px #2196F3;
    }

    .form-check-input:checked + .form-check-label::before {
        -webkit-transform: translateX(80%);
        -ms-transform: translateX(80%);
        transform: translateX(80%);
    }

    .billing-switch {
        div {
            @apply transition-opacity;
        }

        div:not(:has(input:checked)) + div + div {
            opacity: 0.5;
        }

        div:has(input:checked) + div {
            opacity: 0.5;
        }
    }
}