@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./base.css";
@import "./blog.css";
@import "./faq.css";
@import "./features.css";
@import "./footer.css";
@import "./form-check.css";
@import "./content.css";
@import "./checkmarks.css";
@import "./jumbotrons.css";
@import "./navbar.css";
@import "./titles.css";
@import "./sign-up.css";
@import "./swiper.css";
@import "./tooltip.css";

@import "./pricing.css";