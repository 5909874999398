@layer components {
    .pricing-label {
        @apply absolute left-1/2 -top-4 rounded-xl bg-gray-400;
        transform: translateX(-50%);
        padding: 0.6rem 2rem;
    }

    /* Arrow down */
    .pricing-label:after {
        @apply w-0 h-0 absolute top-full left-0 right-0;
        content: '';
        margin: 0 auto;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid transparent;
        @apply border-t-gray-400;
    }

    .pricing-label.highlight {
        @apply bg-global
    }

    .pricing-label.highlight:after {
        @apply border-t-global;
    }

    .pricing-table td,
    .pricing-table th {
        @apply py-4 px-8;
    }

    .price-plans .checkmarks li {
        @apply text-sm md:text-base;
    }
}