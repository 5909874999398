@layer components {
    .sign-up-form::after {
        @apply bg-global block h-full absolute bottom-0 z-10 rounded-full;
        content: '';
        width: 94%;
        left: 3%;
        bottom: -50%;
    }

    /* sm screens */
    @media screen(sm) {
        .sign-up-form::after {
            bottom: -47%;
        }
    }

    /* lg screens */
    @media screen(lg) {
        .sign-up-form::after {
            @apply w-5/12 top-0 left-auto;
            right: -10%;
        }
    }
}