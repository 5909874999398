@layer components {
    ul.checkmarks li {
        @apply relative pl-9 mb-5 w-full;
    }

    ul.checkmarks li:last-child {
        @apply mb-0;
    }

    ul.checkmarks li::before {
        position: absolute;
        content: '';
        background-image: url('../img/content-theme/checkmark.svg');
        background-size: 9px 9px;
        background-position: center;
        background-repeat: no-repeat;
        color: white;
        display: block;
        line-height: 15px;
        text-align: center;
        width: 15px;
        height: 15px;
        left: 0px;
        top: 1px;
        padding: 10px;
        border-radius: 100%;
        @apply bg-global;
    }

    ul.checkmarks-tighter li {
        @apply mt-3 mb-0 pb-3 pl-7 lg:text-sm border-b border-gray-100;
    }

    ul.checkmarks-tighter li:last-child {
        @apply border-b-0;
    }

    ul.checkmarks-tighter li::before {
        padding: 8px;
        top: 2px;
    }

    ul.checkmarks-green li::before {
        @apply bg-green-600;
    }

    ul.checkmarks-purple li::before {
        @apply bg-purple-600;
    }

    .checkmark {
        @apply inline-block;
        position: relative;
        width: 15px;
        height: 15px;
    }

    .checkmark::before {
        position: absolute;
        content: '';
        background-image: url('../img/content-theme/checkmark.svg');
        background-size: 10px 10px;
        background-position: center;
        background-repeat: no-repeat;
        color: white;
        display: block;
        line-height: 15px;
        text-align: center;
        width: 20px;
        height: 20px;
        left: 50%;
        top: 50%;
        border-radius: 100%;
        @apply bg-global;
        transform: translate(-50%, -50%)
    }

    .checkmark.inverted::before {
        @apply bg-white;
        background-image: url('../img/content-theme/checkmark-blue.svg');
    }
}