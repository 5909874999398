@import 'swiper/swiper.css';
@import 'swiper/modules/navigation.css';
@import 'swiper/modules/pagination.css';

@layer components {
    .swiper {
        height: 630px;
    }

    /* Quotation mark */
    .swiper-slide::before {
        @apply absolute left-0 top-0 ml-8 mt-6 text-white text-8xl;
        content: '“';
    }

    /* Sliders content */
    .slider-content {
        @apply absolute top-0 left-0 z-10 flex flex-col gap-8 h-full w-full px-8 items-center justify-center text-white text-center font-medium;
    }

    .slider-content-text {
        @apply text-xl xs:text-2xl lg:text-3xl max-w-screen-md leading-relaxed;
    }

    .slider-content-person {
        @apply text-base xs:text-lg;
    }

    .slider-content-person::before {
        @apply inline-block border-b w-4 h-1 mr-1;
        content: '';
        vertical-align: super;
    }

    /* Navigation */
    .swiper-navigation {
        @apply absolute bottom-0 left-0 w-full mb-14;
    }

    .swiper-navigation .swiper-button {
        @apply rounded-full bg-white h-14 w-14 mx-4;
    }

    .swiper-navigation .swiper-button::after {
        @apply flex h-full w-full;
        content: '';
        background-color: #386def;
        -webkit-mask-image: url('../img/content-theme/icon-arrow.svg');
        mask-image: url('../img/content-theme/icon-arrow.svg');
        mask-repeat: no-repeat;
        mask-position: center;
    }

    .swiper-navigation .swiper-button-prev::after {
        @apply rotate-180;
    }

    /* Navigation - disabled */
    .swiper-navigation .swiper-button-disabled {
        @apply !opacity-100 bg-opacity-10;
    }

    .swiper-navigation .swiper-button-disabled::after {
        background-color: #fff;
    }

    /* Pagination */
    .swiper-pagination {
        margin-bottom: 1.8rem;
    }

    .swiper-pagination .swiper-pagination-bullet {
        @apply bg-transparent opacity-60 h-3 w-3 !mx-2 border border-white;
    }

    .swiper-pagination .swiper-pagination-bullet-active {
        @apply bg-white opacity-100;
    }

    /* Responsive */
    @media screen(md) {
        .swiper {
            height: 420px;
        }

        .swiper-slide::before {
            @apply mt-10;
        }

        .swiper-pagination {
            @apply mb-6;
        }

        .swiper-navigation {
            @apply right-0 left-auto mr-6;
            width: 115px;
        }

        .swiper-navigation .swiper-button {
            @apply h-11 w-11 mx-0;
        }

        .swiper-navigation .swiper-button::after {
            mask-size: 35%;
        }
    }

    @media screen(ml) {
        .swiper-slide::before {
            @apply ml-20;
        }
    }
}