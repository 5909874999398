@layer components {
    .tooltip {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    .tooltip .tooltip-text {
        visibility: hidden;
        width: 260px;
        background-color: dimgray;
        color: #fff;
        text-align: center;
        padding: 10px;
        border-radius: 6px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -130px; /* Use half of the width (260/2 = 130), to center the tooltip */
    }

    .tooltip .tooltip-label {
        border-bottom: 1px dotted dimgray;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltip-text {
        visibility: visible;
    }

    /*.tooltip .tooltip-text::after {*/
    /*    content: " ";*/
    /*    position: absolute;*/
    /*    top: 100%; !* At the bottom of the tooltip *!*/
    /*    left: 50%;*/
    /*    margin-left: -5px;*/
    /*    border-width: 5px;*/
    /*    border-style: solid;*/
    /*    border-color: dimgray transparent transparent transparent;*/
    /*}*/

    .tooltip .tooltip-text {
        opacity: 0;
        transition: opacity 1s;
    }

    .tooltip:hover .tooltip-text {
        opacity: 1;
    }

    @media (max-width: 700px) {
        .tooltip {
            width: 100%;
        }
    }
}