@layer components {

    /* Styled content */
    .styled-content {
        @apply text-gray-700;
        font-size: 0.915rem;
    }

    .styled-content a {
        @apply text-global underline hover:no-underline;
    }

    .styled-content ol {
        @apply list-decimal list-inside mb-6;
    }

    .styled-content h2 {
        @apply text-gray-700 mt-8 mb-5;
        font-size: 1.35rem;
    }

    .styled-content h3 {
        @apply text-base text-gray-700 font-semibold mb-2;
    }

    /* Table of contents */
    .table-of-contents {
        @apply border border-gray-300 p-5 sm:max-w-[300px] rounded-xl;
    }

    .table-of-contents h2 {
        @apply text-xl text-gray-700 font-semibold border-b border-gray-300 mb-4 pb-3;
    }

    .table-of-contents a {
        @apply text-gray-500 font-medium hover:text-global transition-colors;
        font-size: 0.925rem;
    }

    .table-of-contents ul {
        @apply space-y-2;
    }
}