@layer components {
    .footer {
        background: #061232;
    }

    .footer .footer-links {
        @apply w-1/2 lg:w-3/12 ml-auto mb-12;
    }

    .footer .footer-links-title {
        @apply block font-semibold mb-6 lg:mb-8 text-white;
    }

    .footer .footer-links ul {
        @apply space-y-5;
    }

    .footer .footer-links ul li a {
        @apply block hover:underline text-sm xl:text-base;
    }
}