@layer components {
    #for-enterpreneurs {
        .text-content {
            @apply lg:pt-16;
        }

        .jumbotron-title {
            max-width: 450px;
        }

        .jumbotron-image-wrapper {
            @apply flex justify-center;
        }

        .jumbotron-image-wrapper img {
            @apply w-auto rounded-none shadow-none;
            max-height: 400px;
        }
    }

    .jumbotron-without-shadow img {
        @apply shadow-none;
    }

    .jumbotron-without-rounded img {
        @apply rounded-none;
    }

    .jumbotron-bigger-img-width img {
        max-width: 510px !important;
    }

    .jumbotron-centered .jumbotron {
        @apply items-center;
    }

    .jumbotron-smaller-images img {
        @apply max-w-[450px];
    }

    .jumbotron-medium-images img {
        @apply max-w-[480px];
    }

    /* Colored */
    .jumbotron-colored .checkmarks li {
        @apply text-global text-sm font-medium border-b border-slate-300 pb-4 mb-4;
    }

    .jumbotron-colored .checkmarks li:last-child {
        @apply border-none pb-0;
    }
}